<template>
  <header
    id="header"
    class="relative top-0 z-40 flex h-[72px] w-full flex-row items-center justify-between bg-bg-dark px-3 text-white sm:fixed lg:px-10"
  >
    <NuxtLink class="relative z-10" to="/">
      <ClientOnly>
        <Logo class="hidden lg:block" />
        <img class="w-8 lg:hidden" src="/assets/images/small-logo.png" alt="logo" />
      </ClientOnly>
    </NuxtLink>

    <nav
      v-if="authStore.getIsLoggedIn"
      class="absolute inset-x-0 mx-auto hidden flex-row items-center justify-center gap-9 md:flex"
    >
      <NuxtLink
        v-for="item in navItems"
        :key="item.icon"
        :to="item.path"
        class="flex flex-row gap-4 no-underline transition-all duration-500"
        :class="{
          'rounded-xl bg-white/10 px-6 py-3': item.routesIncluded.find((r) => route.name?.toString() === r),
        }"
      >
        <span class="relative">
          <UiIcon :name="item.icon"></UiIcon>
          <UiIcon v-if="item.dot" name="dot" class="absolute -right-px top-0 !h-2 !w-2 text-error-100"></UiIcon
        ></span>

        <h5 v-if="item.routesIncluded.find((r) => route.name?.toString() === r)">{{ item.name }}</h5>
      </NuxtLink>
    </nav>
    <div v-if="authStore.getIsLoggedIn" class="z-10 flex flex-row items-center gap-5">
      <HeaderGlobalSearch />
      <div v-if="!authStore.getIsMarketer" id="notifications-button" class="relative hidden md:block">
        <UiIcon
          name="notification"
          class="cursor-pointer text-white"
          size="xs"
          @click="uiStore.showSidePanel(SIDE_PANELS.NOTIFICATIONS)"
        />
        <UiIcon
          v-if="uiStore.getHasNewNotifications"
          name="dot"
          class="absolute right-0 top-0 !h-2 !w-2 text-error-100"
        ></UiIcon>
      </div>
      <HeaderUserMenu />
    </div>
  </header>
</template>

<script setup lang="ts">
import { SIDE_PANELS, TOURS } from '@/components/dynamic/maps'
import { PERMISSIONS, TOURS_IDS } from '@/constants'
import { useAuthStore } from '~/store/auth'
import { useUiStore } from '~/store/ui'
import { useWhatsappStore } from '~/store/whatsapp'
import Logo from '@/assets/images/logo.svg?component'

const authStore = useAuthStore()
const uiStore = useUiStore()
const whatsappStore = useWhatsappStore()

// const hasNewMessages = computed(() => whatsappStore.getChats.some((c) => c.unreadCount > 0))

const route = useRoute()
const allNavItems = computed(() => [
  {
    icon: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    routesIncluded: ['dashboard', 'dashboard-calls', 'dashboard-campaigns'],
    condition: () =>
      useHasPermissions([
        PERMISSIONS.SEE_AGENT_DASHBOARD,
        PERMISSIONS.SEE_MANAGER_DASHBOARD,
        PERMISSIONS.LEAD_MANAGER_DASHBOARD,
        PERMISSIONS.SDR_DASHBOARD,
      ]),
  },
  {
    icon: 'kanban',
    name: 'Leads',
    path:
      authStore.getIsSdr && authStore.getUser.sdr_queue_eligible && useHasPermissions([PERMISSIONS.MANAGE_QUEUE])
        ? '/pipelines/queue'
        : '/pipelines/sales',
    routesIncluded: ['pipelines-queue', 'pipelines-sales', 'pipelines-pool', 'pipelines-archive', 'leads-id'],
    condition: () =>
      useHasPermissions([
        PERMISSIONS.MANAGE_QUEUE,
        PERMISSIONS.MANAGE_POOL,
        PERMISSIONS.MANAGE_ARCHIVE,
        PERMISSIONS.SEE_ALL_LEADS,
        PERMISSIONS.SEE_ASSIGNED_LEADS,
        PERMISSIONS.SEE_CREATED_LEADS,
        PERMISSIONS.SEE_MARKETER_LEADS_PIPELINE,
        PERMISSIONS.SEE_MARKETER_LEADS_POOL,
      ]),
  },
  {
    icon: 'file-text-1',
    name: 'Deals',
    path: '/deals/all/current',
    routesIncluded: ['deals-all-current', 'deals-all-archive', 'deals-id'],
    condition: () =>
      useHasPermissions([PERMISSIONS.VIEW_MY_DEALS, PERMISSIONS.VIEW_MY_TEAMS_DEALS, PERMISSIONS.VIEW_ALL_DEALS]),
  },
  {
    icon: 'calendar',
    name: 'Activities',
    path: '/activities',
    routesIncluded: ['activities'],
    condition: () => useHasPermissions([PERMISSIONS.MANAGE_ACTIVITIES]),
  },
  {
    icon: 'home',
    name: 'Property',
    path: '/properties',
    routesIncluded: ['properties', 'properties-id'],
    condition: () => useHasPermissions([PERMISSIONS.VIEW_PROPERTY_CATALOGUE]),
  },
  {
    icon: 'shuffle',
    name: 'Rotations',
    path: '/rotations/default',
    routesIncluded: [
      'rotations-default',
      'rotations-custom',
      'rotations-mappings',
      'rotations-settings',
      'rotations-data-enrichment',
    ],
    condition: () => useHasPermissions([PERMISSIONS.MANAGE_ROTATIONS]),
  },
  {
    icon: 'settings',
    name: 'Admin Settings',
    path: '/admin-settings/people/users',
    routesIncluded: [
      'admin-settings-people-users',
      'admin-settings-people-marketers',
      'admin-settings-people-teams',
      'admin-settings-people-permissions',
      'admin-settings-people-user-permissions',
      'admin-settings-telephony-locations-numbers',
      'admin-settings-telephony-support-numbers',
      'admin-settings-leads-settings',
      'admin-settings-leads-sdr',
      'admin-settings-release',
      'admin-settings-notifications-settings',
      'admin-settings-notifications-templates',
    ],
    condition: () =>
      useHasPermissions([
        PERMISSIONS.ASSIGN_ROLES,
        PERMISSIONS.ASSIGN_PERMISSIONS,
        PERMISSIONS.CREATE_NEW_TEAM,
        PERMISSIONS.EDIT_DELETE_SALES_TEAM,
        PERMISSIONS.EDIT_TEAM_AGENTS,
        PERMISSIONS.EDIT_TEAM_MANAGER,
      ]),
  },
  // {
  //   icon: 'mail',
  //   name: 'Inbox',
  //   path: `${whatsappStore.getHasIntegration ? '/inbox/whatsapp/messages' : '/inbox'}`,
  //   routesIncluded: [
  //     'inbox',
  //     'inbox-whatsapp-templates',
  //     'inbox-whatsapp-messages',
  //     'inbox-email-templates',
  //     'inbox-email-messages',
  //   ],
  //   dot: false,
  // },
])

const navItems = computed(() =>
  authStore.getIsLoggedIn ? allNavItems.value.filter((i) => (i.condition ? i.condition() : true)) : []
)

onMounted(() => {
  if (authStore.getIsLoggedIn) {
    uiStore.showTour({
      component: TOURS.GLOBAL_SEARCH,
      id: TOURS_IDS.GLOBAL_SEARCH,
    })
  }
})
</script>

<style scoped></style>
