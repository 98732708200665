import qs from 'qs'
import { useLibrariesStore } from '@/store/libraries'
import type { ApiResponse, ErrorApiResponse, PaginationResponse, Pipeline } from '@/types'

export const useGetPipelineById = async (id: number): Promise<Pipeline> => {
  const { data, error } = await useAPIFetch(`/pipelines/${id}`, {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  return toRaw((data.value as any)?.pipeline)
}

export const useGetPipelineByCode = async (code: string): Promise<Pipeline> => {
  const librariesStore = useLibrariesStore()
  const cachedPipeline = librariesStore.pipelines.find((p) => p.code === code)
  if (cachedPipeline) {
    return cachedPipeline
  }
  const { data, error } = await useAPIFetch(`/pipelines/code/${code}`, {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  const pipeline = toRaw((data.value as any)?.pipeline)
  librariesStore.addPipeline(pipeline)
  return pipeline
}

export const useGetPipelineLeads = async (id: number, query: any): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(query, { arrayFormat: 'comma', encode: false })

  const { data, error } = await useAPIFetch(`/pipelines/${id}/leads?${queryFormatted}`, {
    key: queryFormatted,
  })
  if (error.value) throw createError(error.value)
  return toRaw(data.value as PaginationResponse)
}

export const useGetTablePipelineLeads = async (query: any): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(query, { arrayFormat: 'indices', encode: false })

  const { data, error } = await useAPIFetch(`/pipelines/sales/leads?${queryFormatted}`, {
    key: queryFormatted,
  })
  if (error.value) throw createError(error.value)
  return toRaw(data.value as PaginationResponse)
}

export const useGetArchivedLeads = async (query: any): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(query, { arrayFormat: 'indices', encode: false })

  const { data, error } = await useAPIFetch(`/pipelines/archive/leads?${queryFormatted}`, {
    key: queryFormatted,
  })
  if (error.value) throw createError(error.value)
  return toRaw(data.value as PaginationResponse)
}

export const useGetNextLead = async (id: number, leadId: number, filters: any, gridView: boolean) => {
  const queryFormatted = qs.stringify(
    { ...filters, current_lead_id: leadId, is_kanban: gridView },
    { arrayFormat: 'comma', encode: false }
  )

  return await useAPIFetch<ApiResponse, ErrorApiResponse>(`pipelines/${id}/leads/next?${queryFormatted}`, {
    method: 'get',
  })
}

export const usePipelineSalesQuickFilters = async () =>
  await useAPIFetch(`/dictionary/pipeline_sales_leads_quick_filter_types`)

export const useArchiveQuickFilters = async () =>
  await useAPIFetch(`/dictionary/pipeline_archive_leads_quick_filter_types`)
