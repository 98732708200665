import type { ReleaseResponse } from './releases'
import { useMainStore } from '@/store'
import { useAuthStore } from '@/store/auth'
import { version } from '@/package.json'
import { ENVS, RELEASE_COMPONENTS_CODES } from '@/constants'
import type { ApiResponse } from '~/types'

export const useVersionCheck = async () => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()
  const currentEnv = useRuntimeConfig().public.APP_ENV

  type ReleaseResponseComponent = ReleaseResponse['components'][number]

  const { data: FEComponent, error: FEComponentErrors } = await useAPIFetch<ApiResponse<ReleaseResponseComponent>>(
    `/releases/components`,
    {
      query: {
        code: RELEASE_COMPONENTS_CODES.FE_CRM,
      },
    }
  )

  if (FEComponentErrors.value) throw createError(FEComponentErrors.value)

  const { data: BEComponent, error: BEComponentErrors } = useAPIFetch<ApiResponse<ReleaseResponseComponent>>(
    `/releases/components`,
    {
      query: {
        code: RELEASE_COMPONENTS_CODES.BE_CRM,
      },
    }
  )

  watch(
    BEComponent,
    (value) => {
      if (value) {
        mainStore.setApiVersion(value.data.version)
      }
    },
    {
      once: true,
    }
  )

  // Frontend is not updated
  if (FEComponent.value?.data.version !== version && !authStore.getIsAdmin && currentEnv !== ENVS.DEV) {
    useLogout(true)
    return window.location.reload()
  }

  if (BEComponentErrors.value) throw createError(BEComponentErrors.value)

  return FEComponent.value
}
