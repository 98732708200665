export const POPUPS = {
  ACTIVITY_DELETE: defineAsyncComponent(() => import(`@/components/activity/DeletePopup.vue`)),
  ADMIN_SETTINGS_RELEASE_DELETE: defineAsyncComponent(
    () => import(`@/components/admin-settings/release/DeletePopup.vue`)
  ),
  ADMIN_SETTINGS_TELEPHONY_DELETE: defineAsyncComponent(
    () => import(`@/components/admin-settings/telephony/DeletePopup.vue`)
  ),
  ADMIN_SETTINGS_TELEPHONY_TEST_NUMBER_POPUP: defineAsyncComponent(
    () => import(`@/components/admin-settings/telephony/TestNumberPopup.vue`)
  ),
  LEAD_CALL_POST_CALL: defineAsyncComponent(() => import(`@/components/lead/call/PostCallPopup.vue`)),
  LEAD_CALL_OUTBOUND_OUTCOME: defineAsyncComponent(() => import(`@/components/lead/call/OutboundOutcomePopup.vue`)),
  LEAD_CALL_AGAIN: defineAsyncComponent(() => import(`@/components/lead/call/AgainPopup.vue`)),
  LEAD_CALL_INBOUND_FINISHED: defineAsyncComponent(() => import(`@/components/lead/call/InboundFinishedPopup.vue`)),
  LEAD_CREATE_DEAL_FOR_CLOSED: defineAsyncComponent(
    () => import(`@/components/lead/info/details/CreateDealForClosedPopup.vue`)
  ),
  PIPELINE_LEAD_CLOSE: defineAsyncComponent(() => import(`@/components/pipeline/lead/ClosePopup.vue`)),
  PIPELINE_LEAD_CLOSE_LOST: defineAsyncComponent(() => import(`@/components/pipeline/lead/CloseLostPopup.vue`)),
  PIPELINE_LEAD_UNSUCCESSFUL_CONTACT: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/UnsuccessfulContactPopup.vue`)
  ),
  LEAD_INFO_ADD_COUNTRY_CODE: defineAsyncComponent(() => import(`@/components/lead/info/AddCountryCodePopup.vue`)),
  PEOPLE_TEAM_CANT_BE_DELETED: defineAsyncComponent(() => import(`@/components/people/team/CantBeDeletedPopup.vue`)),
  PEOPLE_TEAM_DELETE: defineAsyncComponent(() => import(`@/components/people/team/DeletePopup.vue`)),
  PEOPLE_MARKETERS_DELETE: defineAsyncComponent(() => import(`@/components/people/marketers/DeletePopup.vue`)),
  PEOPLE_USERS_DELETE: defineAsyncComponent(() => import(`@/components/people/users/DeletePopup.vue`)),
  PEOPLE_USERS_CANT_DELETE_HAS_ROTATIONS: defineAsyncComponent(
    () => import(`@/components/people/users/CantDeleteHasRotationsPopup.vue`)
  ),
  PEOPLE_USERS_CANT_DELETE_HAS_LEADS: defineAsyncComponent(
    () => import(`@/components/people/users/CantDeleteHasLeadsPopup.vue`)
  ),
  PEOPLE_USERS_CANT_DELETE: defineAsyncComponent(() => import(`@/components/people/users/CantDeletePopup.vue`)),
  PEOPLE_USERS_CANT_CHANGE_ROLE: defineAsyncComponent(
    () => import(`@/components/people/users/CantChangeRolePopup.vue`)
  ),
  PEOPLE_USERS_TURN_OFF_TWO_FA_POPUP: defineAsyncComponent(
    () => import(`@/components/people/users/TurnOffTwoFAPopup.vue`)
  ),
  PEOPLE_USER_PERMISSIONS_DELETE: defineAsyncComponent(
    () => import(`@/components/people/user-permissions/DeletePopup.vue`)
  ),
  PIPELINE_LEAD_ASSIGN_AGENT: defineAsyncComponent(() => import(`@/components/pipeline/lead/AssignAgentPopup.vue`)),
  PIPELINE_LEAD_MOVE_TO_ARCHIVE: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/MoveToArchivePopup.vue`)
  ),
  PIPELINE_LEAD_MOVE_TO_POOL: defineAsyncComponent(() => import(`@/components/pipeline/lead/MoveToPoolPopup.vue`)),
  PIPELINE_LEAD_REOPEN: defineAsyncComponent(() => import(`@/components/pipeline/lead/ReopenPopup.vue`)),
  PIPELINE_LEAD_CREATE_SHADOW: defineAsyncComponent(() => import(`@/components/pipeline/lead/CreateShadowPopup.vue`)),
  PIPELINE_LEAD_CANT_CREATE_SHADOW: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/CantCreateShadowPopup.vue`)
  ),
  PIPELINE_LEAD_CANT_CREATE_SHADOW_HAS_SHADOW: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/CantCreateShadowHasShadowPopup.vue`)
  ),
  PIPELINE_LEAD_RETURN_TO_QUEUE: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/ReturnToQueuePopup.vue`)
  ),
  PIPELINE_LEAD_SEND_TO_QUEUE: defineAsyncComponent(() => import(`@/components/pipeline/lead/SendToQueuePopup.vue`)),
  PIPELINE_LEAD_MOVE_TO_ROTATION_QUEUE: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/CheckMoveToRotationQueuePopup.vue`)
  ),
  TRY_CALL_LATER_POPUP: defineAsyncComponent(() => import(`@/components/TryCallLaterPopup.vue`)),
  SETTINGS_PHOTO_UPLOAD: defineAsyncComponent(() => import(`@/components/settings/PhotoUploadPopup.vue`)),
  SETTINGS_TWO_FA: defineAsyncComponent(() => import(`@/components/settings/TwoFAPopup.vue`)),
  ROTATION_SETTINGS_DELETE: defineAsyncComponent(() => import(`@/components/rotation/settings/DeletePopup.vue`)),
  ROTATION_MAPPING_DELETE: defineAsyncComponent(() => import(`@/components/rotation/mapping/DeletePopup.vue`)),
  ROTATION_CUSTOM_DELETE: defineAsyncComponent(() => import(`@/components/rotation/custom/DeletePopup.vue`)),
  PIPELINE_LEAD_MOVE_FROM_DORMANT: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/MoveFromDormantPopup.vue`)
  ),
  PIPELINE_LEAD_MOVE_TO_DORMANT: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/MoveToDormantPopup.vue`)
  ),
  PIPELINE_LEAD_MOVE_TO_CALL_LATER: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/MoveToCallLaterPopup.vue`)
  ),
  PIPELINE_LEAD_DUPLICATE: defineAsyncComponent(() => import(`@/components/pipeline/lead/CreateDuplicatePopup.vue`)),
  PIPELINE_LEAD_DUPLICATE_SUGGEST: defineAsyncComponent(
    () => import(`@/components/pipeline/lead/DuplicateSuggest.vue`)
  ),
  DEAL_ARCHIVE_POPUP: defineAsyncComponent(() => import(`@/components/deal/ArchivePopup.vue`)),
  DEAL_CANCEL_POPUP: defineAsyncComponent(() => import(`@/components/deal/CancelPopup.vue`)),
  DEAL_DOWN_PAYMENT_DELETE_POPUP: defineAsyncComponent(
    () => import(`@/components/deal/stages/down-payment/DeletePopup.vue`)
  ),
  DEAL_PAYMENTS_INVOICES_DELETE_POPUP: defineAsyncComponent(
    () => import(`@/components/deal/stages/payments/invoices/DeletePopup.vue`)
  ),
  DEAL_PAYMENTS_INCOMING_DELETE_POPUP: defineAsyncComponent(
    () => import(`@/components/deal/stages/payments/incoming/DeletePopup.vue`)
  ),
  DEAL_PAYOUTS_COMPLETED: defineAsyncComponent(
    () => import(`@/components/deal/stages/payments/payouts/CompletedPopup.vue`)
  ),
  DEAL_INFO_AGENT_COMMISSION: defineAsyncComponent(() => import(`@/components/deal/info/AgentCommissionPopup.vue`)),
  DEAL_INFO_SM_COMMISSION: defineAsyncComponent(() => import(`@/components/deal/info/SalesManagerCommissionPopup.vue`)),
  INBOX_CONNECT_POPUP: defineAsyncComponent(() => import(`@/components/inbox/ConnectPopup.vue`)),
  CONNECT_WHATSAPP_BY_QR: defineAsyncComponent(
    () => import(`@/components/inbox/whatsapp/ConnectWhatsappByQRPopup.vue`)
  ),
  ADD_NOTE_LEAD: defineAsyncComponent(() => import(`@/components/inbox/whatsapp/AddNoteLeadPopup.vue`)),
  INBOX_CONNECT: defineAsyncComponent(() => import(`@/components/inbox/ConnectPopup.vue`)),
  IMAGE_UPLOAD: defineAsyncComponent(() => import(`@/components/inbox/whatsapp/chat/ImageUploadPopup.vue`)),
  FILE_UPLOAD: defineAsyncComponent(() => import(`@/components/inbox/whatsapp/chat/FileUploadPopup.vue`)),
  DELETE_ATTACHMENT: defineAsyncComponent(() => import(`@/components/inbox/whatsapp/chat/DeleteAttachmentPopup.vue`)),
  FULL_IMAGE: defineAsyncComponent(() => import(`@/components/inbox/whatsapp/chat/FullImagePopup.vue`)),
  WHATSAPP_FAILED_CONNECTION: defineAsyncComponent(
    () => import(`@/components/inbox/whatsapp/FailedConnectionPopup.vue`)
  ),
  WA_CREATE_SCHEDULE_MESSAGE: defineAsyncComponent(
    () => import(`@/components/inbox/whatsapp/chat/CreateScheduleMessagePopup.vue`)
  ),
  WA_DELETE_SCHEDULED_MESSAGE: defineAsyncComponent(
    () => import(`@/components/inbox/whatsapp/chat/DeleteScheduleMessagePopup.vue`)
  ),
  LEAD_MOBILE_INTERESTS: defineAsyncComponent(() => import(`@/components/lead/mobile/InterestsPopup.vue`)),
  LEAD_CALL_METHODS: defineAsyncComponent(() => import(`@/components/lead/call/MethodsPopup.vue`)),
  LEAD_CALL_POST_STAGE_REMINDER: defineAsyncComponent(
    () => import(`@/components/lead/call/PostCallStageReminderPopup.vue`)
  ),
  FILTERS_MOBILE_PANEL_SEE_MORE: defineAsyncComponent(() => import(`@/components/filters/MobilePanelSeeMorePopup.vue`)),
  DATA_ENRICHMENT_RULE_CREATE: defineAsyncComponent(
    () => import(`@/components/rotation/data-enrichment/rule/create/Popup.vue`)
  ),
  DATA_ENRICHMENT_RULE_DELETE: defineAsyncComponent(
    () => import(`@/components/rotation/data-enrichment/rule/DeletePopup.vue`)
  ),
  PROPERTIES_FILTERS: defineAsyncComponent(() => import(`@/components/properties/FiltersPopup.vue`)),
  PROPERTY_FULL_PHOTOS: defineAsyncComponent(() => import(`@/components/property/FullPhotosPopup.vue`)),
  PROPERTY_UNIT_INFO: defineAsyncComponent(() => import(`@/components/property/UnitInfoPopup.vue`)),
  PROPERTY_CREATE_OFFER: defineAsyncComponent(() => import(`@/components/property/CreateOfferPopup.vue`)),
  FINANCE_SAVE_CONTACTS: defineAsyncComponent(
    () => import(`@/components/deal/stages/new-deal/buyer-info/SaveContactsPopup.vue`)
  ),
  CONFIRM_ADDING_REFERRAL_TAG: defineAsyncComponent(() => import(`@/components/deal/info/ConfirmPopup.vue`)),
  CONFIRM_DELETING_REFERRAL_TAG: defineAsyncComponent(() => import(`@/components/deal/info/DeletePopup.vue`)),
  MEETING_OUTCOME: defineAsyncComponent(() => import(`@/components/activity/OutcomePopup.vue`)),
  MEETINGS_OUTCOME_REMINDER: defineAsyncComponent(() => import(`@/components/activity/OutcomeReminderPopup.vue`)),
  OVERDUE_TASKS: defineAsyncComponent(() => import(`@/components/activity/OverdueTasksPopup.vue`)),
  CREATE_RELEASE: defineAsyncComponent(() => import(`@/components/admin-settings/release/ReleaseCreate.vue`)),
}

export const SIDE_PANELS = {
  NOTIFICATIONS: defineAsyncComponent(() => import(`@/components/notifications/SidePanel.vue`)),
  ACTIVITY_PREVIEW: defineAsyncComponent(() => import(`@/components/activity/preview/SidePanel.vue`)),
  ACTIVITY_CREATE_EDIT: defineAsyncComponent(() => import(`@/components/activity/create/EditSidePanel.vue`)),
  LEAD_INFO_INTERESTS: defineAsyncComponent(() => import(`@/components/lead/info/interests/EditSidePanel.vue`)),
  LEAD_INFO_DETAILS: defineAsyncComponent(() => import(`@/components/lead/info/details/EditSidePanel.vue`)),
  PEOPLE_ROLES_SET: defineAsyncComponent(() => import(`@/components/people/roles/SetSidePanel.vue`)),
  PIPELINE_LEAD_CREATE: defineAsyncComponent(() => import(`@/components/pipeline/lead/CreateSidePanel.vue`)),
  PIPELINE_DEAL_CREATE: defineAsyncComponent(() => import(`@/components/pipeline/lead/CreateDealSidePanel.vue`)),
  PEOPLE_TEAM_CREATE_EDIT: defineAsyncComponent(() => import(`@/components/people/team/CreateEditSidePanel.vue`)),
  ADMIN_SETTINGS_TELEPHONY_CREATE_LOCATION: defineAsyncComponent(
    () => import(`@/components/admin-settings/telephony/CreateLocationSidePanel.vue`)
  ),
  ADMIN_SETTINGS_NOTIFICATIONS_CREATE_TEMPLATE: defineAsyncComponent(
    () => import(`@/components/admin-settings/templates/CreateSidePanel.vue`)
  ),
  ROTATION_CUSTOM_CREATE: defineAsyncComponent(() => import(`@/components/rotation/custom/CreateSidePanel.vue`)),
  ROTATION_DEFAULT_CREATE: defineAsyncComponent(() => import(`@/components/rotation/default/CreateSidePanel.vue`)),
  ROTATION_EDIT_MAPPINGS: defineAsyncComponent(() => import(`@/components/rotation/EditMappingsSidePanel.vue`)),
  ROTATION_MAPPING_CREATE_EDIT: defineAsyncComponent(
    () => import(`@/components/rotation/mapping/CreateEditSidePanel.vue`)
  ),
}

export const TOURS = {
  CALL_LOG: defineAsyncComponent(() => import(`@/components/tours/CallLogs.vue`)),
  GLOBAL_SEARCH: defineAsyncComponent(() => import(`@/components/tours/GlobalSearch.vue`)),
  ACTIVITY_CREATION_TYPES: defineAsyncComponent(() => import(`@/components/tours/ActivityCreationTypes.vue`)),
  ACTIVITY_MEETING_CREATION: defineAsyncComponent(() => import(`@/components/tours/ActivityMeetingCreation.vue`)),
  SDR: defineAsyncComponent(() => import(`@/components/tours/SDRQueue.vue`)),
  NEWLY_ADDED: defineAsyncComponent(() => import(`@/components/tours/NewlyAdded.vue`)),
  WELCOME_ABOARD: defineAsyncComponent(() => import(`@/components/tours/WelcomeAboard.vue`)),
  PROPERTIES_OFFERS: defineAsyncComponent(() => import(`@/components/tours/PropertiesOffers.vue`)),
  PIN_NOTE: defineAsyncComponent(() => import(`@/components/tours/PinNote.vue`)),
  QUICK_FILTERS: defineAsyncComponent(() => import(`@/components/tours/QuickFilters.vue`)),
}

export const MOBILE_PANELS = {
  PIPELINE_SALES_FILTERS: defineAsyncComponent(() => import(`@/components/filters/MobilePanel.vue`)),
}

export const ACTION_BARS = {
  PIPELINE_POOL_ASSIGN_AGENT: defineAsyncComponent(
    () => import(`@/components/pipeline/pool/AssignToAgentActionBar.vue`)
  ),
  PIPELINE_POOL_MOVE_TO_PIPELINE: defineAsyncComponent(
    () => import(`@/components/pipeline/pool/MoveToPipelineActionBar.vue`)
  ),
  PIPELINE_SALES: defineAsyncComponent(() => import(`@/components/pipeline/sales/ActionBar.vue`)),
  PIPELINE_QUEUE: defineAsyncComponent(() => import(`@/components/pipeline/queue/ActionBar.vue`)),
}
