import { defineStore } from 'pinia'
import merge from 'lodash/merge'
import { useAuthStore } from '@/store/auth'
import type { WhatsappChat, WhatsappMessage } from '@/types'

type WhatsappStore = {
  disconnected: boolean
  loading: boolean
  hasIntegration: boolean
  isWAUser: boolean
  validPhone: boolean
  whatsappCurrentNumber: string
  qrCode: string
  chats: WhatsappChat[]
  leadChats: WhatsappChat[]
  chat: WhatsappChat | null
  messages: WhatsappMessage[] | []
  pagination: any | null
  scrollToEnd: boolean
}

export const useWhatsappStore = defineStore('whatsapp', {
  state: (): WhatsappStore => ({
    disconnected: false,
    loading: true,
    hasIntegration: false,
    isWAUser: false,
    validPhone: true,
    whatsappCurrentNumber: '',
    qrCode: '',
    chats: [],
    leadChats: [],
    chat: null,
    messages: [],
    pagination: null,
    scrollToEnd: false,
  }),
  actions: {
    setDisconnected(value: boolean = true) {
      this.disconnected = value
    },
    setLoading(value: boolean = false) {
      this.loading = value
    },
    setIntegration(value: boolean) {
      this.hasIntegration = value
    },
    setWAUser(value: boolean) {
      this.isWAUser = value
    },
    async setValidPhone(value: any) {
      this.validPhone = value.isTheSame
      this.whatsappCurrentNumber = value.whatsappCurrentNumber
      if (!value.isTheSame) {
        await triggerWALogout()
        setTimeout(() => {
          this.validPhone = true
        }, 500)
      }
    },
    setQrCode(value: string) {
      this.qrCode = value
    },
    async setChat(leadId: number) {
      const { data } = await useGetWALeadChats(leadId)
      if (!data.value.data.length) {
        this.resetChat()
        return
      }
      this.chat = data.value.data[0]
      await this.setMessages(this.chat!.whatsappId)
    },
    async setLeadChats(leadId: number, assigneeId?: number) {
      const authStore = useAuthStore()
      const { data } = await useGetWALeadChats(leadId)
      if (!data.value.data.length) {
        this.resetChat()
        return
      }
      this.leadChats = data.value.data

      const myChat = this.leadChats.find((chat: WhatsappChat) => chat.crm_user_contact.userId === authStore.getUser.id)
      const assigneeChat = this.leadChats.find((chat: WhatsappChat) => chat.crm_user_contact.userId === assigneeId)

      if (myChat) {
        this.chat = myChat
      } else if (assigneeChat) {
        this.chat = assigneeChat
      } else {
        this.chat = this.leadChats[0]
      }

      await this.setMessages(this.chat!.whatsappId)
    },
    async changeChat(chatId: string) {
      this.chat = this.leadChats.find((chat: WhatsappChat) => chat.whatsappId === chatId)
      await this.setMessages(this.chat!.whatsappId)
    },
    resetChat() {
      this.chat = null
      this.messages = []
      this.leadChats = []
    },
    async setMessages(chatId: string, older_than_date?: string, more: boolean = false) {
      const { data, pagination } = await useGetWAChatMessages(chatId, older_than_date)
      if (!more) {
        this.messages = data.reverse()
      } else {
        this.messages = [...data.reverse(), ...this.messages]
      }

      this.pagination = pagination
    },
    setNewChat(value: WhatsappChat) {
      this.chat = value
    },
    setNewMessage(value: WhatsappMessage) {
      if (value.chatId !== this.chat?.whatsappId) return
      this.messages.push(value)
      if (value.isFromMe) {
        this.scrollToEnd = true
        setTimeout(() => {
          this.scrollToEnd = false
        }, 100)
      }
    },
    updateChat(value: { unreadCount: number; message: WhatsappMessage }) {
      if (value.message.chatId !== this.chat?.whatsappId) return
      this.chat.unreadCount = value.unreadCount
      this.updateMessage(value.message)
    },
    updateMessage(message: WhatsappMessage) {
      if (message.chatId !== this.chat?.whatsappId) return
      const updatedMessageIndex = this.messages.findIndex((m: WhatsappMessage) => m.id === message.id)
      if (updatedMessageIndex) {
        this.messages[updatedMessageIndex] = merge(this.messages[updatedMessageIndex], message)
      }
    },
    updateUnreadCount(value: any) {
      if (!this.chat || value.chatWhatsappId !== this.chat?.whatsappId) return
      this.chat.unreadCount = value.unreadCount
    },
    setChats(value: any[]) {},
    deleteMessage(id: string) {
      const updatedMessageIndex = this.messages.findIndex((m: WhatsappMessage) => m.id === id)
      this.messages.splice(updatedMessageIndex, 1)
    },
  },
  getters: {
    getDisconnected: (state) => state.disconnected,
    getLoading: (state) => state.loading,
    getHasIntegration: (state) => state.hasIntegration,
    getIsWAUser: (state) => state.isWAUser,
    getValidPhone: (state) => state.validPhone,
    getWhatsappCurrentNumber: (state) => state.whatsappCurrentNumber,
    getQrCode: (state) => state.qrCode,
    getChats: (state) => state.chats,
    getLeadChats: (state) => state.leadChats,
    getChat: (state) => state.chat,
    getMessages: (state) => state.messages,
    getPagination: (state) => state.pagination,
  },
})
